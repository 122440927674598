// Definition of colors
$black: #202020;
$white: #fff;
$gray: #707070;
$color1: #f7e7ff;
$color2: #2792d9;

// Import external files
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// Reset css
body {
  margin: 0;
  background: $white;

  &::-webkit-scrollbar {
    display: none;
  }
}

img {
  vertical-align: top;
}

* {
  font-family: 'Roboto', sans-serif;
  color: $black;
}

// Header
header {
  max-width: 1000px;
  height: 110px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      #logo {
        a {
          text-decoration: none;

          img {
            height: 55px;
          }
        }
      }
    }
  }
}

// Footer
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  max-width: 1000px;
  height: 30px;
  margin: 0 auto;
  margin-top: 100px;

  div {
    color: $gray;
    font-size: 13px;

    a {
      color: $gray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Top
#top {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;

  #intro {
    width: 95%;
    margin: 0 auto;

    h1,
    p,
    img {
      display: block;
      text-align: center;
      margin: 5px auto;
    }

    h1 {
      margin-top: 60px;
    }
  }

  #imageUploader {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    height: 300px;
    background: $color1;
    border: $gray dashed 2px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  #imageEditor {
    display: flex;
    flex-direction: column;
    justify-content: center;

    canvas {
      max-width: 800px;
      margin: 0 auto;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 120px;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0.8rem 4rem;
      font-weight: bold;
      border: 2px solid $color2;
      color: $color2;
      border-radius: 50vh;
      transition: 0.5s;
      background: $white;
      font-size: 15px;

      &:hover {
        color: $white;
        background: $color2;
        cursor: pointer;
      }
    }
  }
}

@media(width <=820px) {
  header {
    height: 100px;

    ul {
      margin: 0 auto;

      li {
        div {
          a {
            img {
              height: 40px;
            }
          }
        }
      }
    }
  }

  #top {
    margin-top: 10px;

    #imageUploader {
      width: calc(88% - 40px);
      height: 250px;
      padding: 20px;
    }

    #imageEditor {
      width: 93%;
      margin: 0 auto;

      canvas {
        width: 100%;
      }
    }
  }
}